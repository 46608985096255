@import '~react-redux-toastr/src/styles/index';

.redux-toastr {
  .top-center {
    width: 500px !important;
    margin-left: -250px !important;
  }

  .toastr {
    div {
      color: #FFF !important;
    }

    .rrt-left-container {
      display: none !important;
    }

    .rrt-middle-container {
      margin-left: 20px !important;
    }
  }
}
