@import '~@src/styles/vendor/utils';
@import '~@src/styles/variables';

.overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow-y: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 95%;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    width: 90%;
  }
}

.body {
  padding: 2rem 1rem;
  border-radius: 0.25rem;
  background-color: $white;
}

.closeButton {
  border: none;
  padding: 0;
  background-color: transparent;
  color: $tandemGray;
  position: absolute;
  top: 40px;
  right: 30px;
  cursor: pointer;
}
