// Variables

//
// Tandem Colors
//

$tandemBlue: #2B388F;
$tandemBrightBlue: #3C58F0;
$tandemYellow: #FFBD48;
$tandemOrange: #FF7D3A;
$tandemGreen: #61D876;
$tandemGrayBackground: #F7F8F9;

$tandemGray: #AFAFAF;
$tandemGrayLight: #DCDEE2;
$tandemGrayDark: #6C6C6C;
$tandemGrayBackground: #F7F8F9;

$late: #F75A5B;
$delayed: #FF7D3A;
$unexpected: #FFBD48;
$onTime: #61D876;

// Tandem Colors
//
// Used to override Bootstrap's

$white:    #FFF !default;
$gray-100: #F8F9FA !default;
$gray-200: #E9ECEF !default;
$gray-300: #DEE2E6 !default;
$gray-400: #CED4DA !default;
$gray-500: #ADB5BD !default;
$gray-600: #6C757D !default;
$gray-700: #495057 !default;
$gray-800: #343A40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:  $tandemBlue !default;
$indigo:  #6610F2 !default;
$purple:  #6F42C1 !default;
$pink:    #E83E8C !default;
$red:     #DC3545 !default;
$orange:  #FD7E14 !default;
$yellow:  #FFC107 !default;
$green:   #28A745 !default;
$teal:    #20C997 !default;
$cyan:    #17A2B8 !default;

$theme-colors: (
  'bright': $tandemBrightBlue
);
