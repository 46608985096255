@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker {
  ul {
    padding: 0;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.invalid-datepicker ~ .invalid-feedback {
  display: block;
}
