@import '~@src/styles/variables';

.tandem-select__menu {
  z-index: 100 !important;
}

.tandem-select {
  .tandem-select__control {
    border-color: transparent;
    background-color: transparent;
  }

  .tandem-select__control--is-focused {
    border-color: $tandemBlue;
  }

  &.form-control-sm {
    height: 31px;

    .tandem-select__control {
      max-height: 29px;
      min-height: 29px;
    }

    .tandem-select__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .tandem-select__indicator {
      padding: 4px;
    }

    .tandem-select__menu {
      margin-top: 2px;
    }
  }
}

.tandem-select__option--is-focused {
  background-color: lighten($tandemBlue, 10%) !important;
  color: $white !important;
}

.tandem-select__option--is-selected {
  background-color: $tandemBlue !important;
  color: $white !important;
}
