.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badges {
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
}

.badge {
  margin-right: 5px;
}
