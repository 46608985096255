.table {
  text-align: center;

  th,
  td {
    vertical-align: middle;
    min-width: 100px;
  }
}

.header {
  margin-top: 2rem;
}
