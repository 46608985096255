@import '~@src/styles/variables';

.vis-item.on_time {
  color: $white;
  background-color: $onTime;
}

.vis-item.late {
  color: $white;
  background-color: $late;
}

.vis-item.delayed {
  color: $white;
  background-color: $delayed;
}

.vis-item.unexpected {
  color: $white;
  background-color: $unexpected;
}
