.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.checkboxes {
  display: flex;
}

.label {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  margin-bottom: 0;
}
