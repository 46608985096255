@import '~@src/styles/variables';

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  padding: 10px 0;
  z-index: 1;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navBarLeft {
  display: flex;
}

.logo {
  height: 1rem !important;
  margin-bottom: 3px;
}

.actions,
.links {
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  button,
  a {
    padding: 0 7px;
    font-size: 0.875rem;
  }
}

.sidebar {
  position: fixed;
  top: 44px;
  left: 0;
  min-height: calc(100vh - 44px);
  background-color: #FFF;
}

.links {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    padding: 0.25rem 0;
  }

  p {
    padding: 0.5rem 0 0.25rem 0;
    margin: 0;
    font-weight: bold;
    color: $tandemBlue;
  }
}

.userName {
  color: $tandemGrayDark;
}

.regionLink {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
